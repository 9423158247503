import React from "react";
import PropTypes from "prop-types";
import Layout from "@app-components/layout/";
import { css } from "@emotion/core";
import Header from "@app-components/ui/header/top_bottom/";
import Testimonial from "@app-components/ui/testimonial";
import Ad from "@media/ground/Ad.svg";
import Customer from "@media/ground/Customer.svg";
import Delight from "@media/ground/Delight.svg";
import Seat from "@media/aero/seat.svg";
import Stock2 from "@media/aero/s2.jpg";
import Anton from "@media/aero/anton.jpg";
import Ground from "@media/aero/on_the_ground.svg";

const OnTheGround = () => (
  <Layout page="ground">
    <Header
      title={
        <h1 style={{ fontWeight: "300" }}>
          Engage with passengers before they take flight
        </h1>
      }
      text="Drive loyalty and extend the customer journey by offering entertainment to passengers on their personal devices in lounges before their flights, or even outside the airport environment with On The Ground's patent-pending PNR-based content entitlement system."
      secondaryNavigation={{
        icon: Ground,
        base_url: "/on-the-ground",
        title: "On The Ground",
        links: []
      }}
    />

    <section className="pt4 px2">
      <div className="max-width-4 mx-auto pb4">
        <div className="clearfix mxn3">
          <div className="sm-col sm-col-12 md-col-4 mb3 center px3">
            <div
              css={css`
                height: 120px;
              `}
              className="center"
            >
              <img
                src={Delight}
                css={css`
                  width: 150px;
                `}
              />
            </div>
            <div
              css={css`
                font-size: 1.6em;
              `}
            >
              Delight Passengers
            </div>
          </div>
          <div className="sm-col sm-col-12 md-col-4 mb3 center px3">
            <div
              css={css`
                height: 120px;
              `}
              className="center"
            >
              <img
                src={Ad}
                css={css`
                  width: 150px;
                `}
              />
            </div>
            <div
              css={css`
                font-size: 1.6em;
              `}
            >
              Capture New Ad Revenue
            </div>
          </div>
          <div className="sm-col sm-col-12 md-col-4 mb3 center px3">
            <div
              css={css`
                height: 120px;
              `}
              className="center"
            >
              <img
                src={Customer}
                css={css`
                  width: 150px;
                `}
              />
            </div>
            <div
              css={css`
                font-size: 1.6em;
              `}
            >
              Drive Customer Loyalty
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      css={css`
        border-bottom: 1px solid #e1e3e4;
        border-top: 1px solid #e1e3e4;
      `}
    >
      <div class="flex split">
        <div class="col-6 flex justify-center items-center">
          <div class="split-inner px4">
            <h2
              className="mb2"
              css={css`
                font-size: 2.2em !important;
                font-weight: 400 !important;
              `}
            >
              An Industry First
            </h2>
            <p
              css={css`
                font-size: 2em !important;
                line-height: 1.25em;
              `}
            >
              Learn how Air Canada deployed On The Ground to offer customers an
              at-home entertainment amid the COVID-19 crisis, keeping passengers
              engaged, and the airline top of mind.
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apex.aero/2020/06/22/air-canada-free-at-home-streaming-service?utm_medium=email&utm_campaign=APEX%20June%2022%202020&utm_content=APEX%20June%2022%202020+CID_21d0eb3a2cf8b36ce563b15d6002b473&utm_source=APEX%20Newsletter&utm_term=EXCLUSIVE%20Air%20Canada%20Launches%20Free%20At-Home%20Streaming%20Service"
              className="section-link flex items-center"
              css={css`
                font-size: 1.2em;
                font-weight: 700;
              `}
            >
              <span>View article on APEX</span>
              <i class="material-icons ml1">arrow_forward</i>
            </a>
          </div>
        </div>
        <div
          class="col-6 bg relative"
          style={{ backgroundImage: `url(${Stock2})` }}
        >
          <div
            css={css`
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              background: rgb(255, 255, 255);
              background: linear-gradient(
                270deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 1) 100%
              );
            `}
          />
        </div>
      </div>
    </section>

    <Testimonial
      image={Anton}
      content={`
        As a premium global airline, Air Canada must always innovate to offer compelling products and services to our discerning customers. CineSend has been a highly innovative and flexible partner and has helped our airline deliver an industry-first with at-home streaming of in-flight entertainment content. We look forward to working with CineSend on future innovations.
      `}
      name={"Anton Vidgen"}
      credit={"Director of Brand Experience, Air Canada"}
    />
  </Layout>
);

OnTheGround.propTypes = {};

export default OnTheGround;
