import React from "react";
import { css } from "@emotion/core";

const Testimonial = ({ image, content, name, credit }) => (
  <section
    className="py4 px2"
    css={css`
      background: #fbfbfb;
    `}
  >
    <div className="max-width-4 mx-auto pb4">
      <div className="clearfix">
        <div className="sm-col sm-col-6 md-col-5 lg-col-4 mb3">
          <img
            src={image}
            css={css`
              width: 200px;
              border-radius: 100px;
            `}
          />
        </div>
        <div className="sm-col sm-col-6 md-col-7 lg-col-8">
          <blockquote className="mb3" css={css`
            font-size: 1.6em;
            line-height: 1.25em !important;
            font-weight: 300;
          `}>"{content}"</blockquote>
          <span css={css`
            font-size: 1.3em;
          `}>{name}</span>
          <br />
          <span
            css={css`
              color: #0d98ff;
              font-size: 1em;
            `}
          >
            {credit}
          </span>
        </div>
      </div>
    </div>
  </section>
);

export default Testimonial;
